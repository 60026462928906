import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout.js";
import Section from "../components/Section";
import HeroSectionWithVideo from "../components/HeroSectionWithVideo";
import BenefitsList from "../components/BenefitsList";
import RenderMarkdown from "../components/utilities/RenderMarkdown.js";
import PreviewCompatibleImage from "../components/utilities/PreviewCompatibleImage.js";
import Introduction from "../components/Introduction.js";
import FAQs from "../components/FAQs";
import Cta from "../components/CTA";
import FlexGroup from "../components/utilities/FlexGroup.js";
import CountdownTimer from "../components/CountdownTimer";
import IconSelect from "../components/utilities/IconSelect.js";

const YetAnotherTemplate2PageTemplate = ({ data }) => {
  return (
    <>
      <Layout
        seoObject={data.seoObject}
        imageObject={data.headerObject.imageObject}
      >
        {/*------------------------- hero -------------------------*/}
        <HeroSectionWithVideo
          className="bg-brand-beige-40"
          heroObject={data.heroObject}
        />

        {/*------------------------- famous -------------------------*/}
        <Section className="">
          <div className={`flex flex-col items-center max-w-4xl mx-auto`}>
            <RenderMarkdown markdownContent={data.famousObject.title} />
            <PreviewCompatibleImage
              imageObject={data.famousObject.imageObject}
            />
          </div>
        </Section>

        {/*------------------------- youKnow -------------------------*/}
        <Section>
          <BenefitsList
            benefitsObject={data.youKnowObject}
            classNameIcon="w-11 p-3 sm:p-7 text-brand-green rotate-90"
            whitespaceList="normal"
          />
        </Section>

        {/*------------------------- imagine -------------------------*/}
        <Section className="">
          <RenderMarkdown
            markdownContent={data.imagineObject.title}
            className="text-center"
          />
          <RenderMarkdown markdownContent={data.imagineObject.description} />
        </Section>

        {/*------------------------- pitch -------------------------*/}
        <Section className="bg-brand-green text-white">
          <div className="flex justify-center">
            <PreviewCompatibleImage
              imageObject={data.pitchBenefitsObject.imageObject}
              className="max-w-4xl w-full"
              loading="lazy"
            />
          </div>
          <BenefitsList
            benefitsObject={data.pitchBenefitsObject.benefitsObject}
            classNameIcon="w-11 p-3 sm:p-7 text-white"
            isIconCentered={true}
            whitespaceList="normal"
          />
          <Cta
            ctaObject={data.pitchBenefitsObject.ctaObject}
            className="py-5 sm:py-10"
          />
        </Section>

        {/*------------------------- textTestimonials -------------------------*/}
        <Section>
          <RenderMarkdown
            markdownContent={data.textReviewObject.title}
            className="text-center"
          />
          <div className="flex flex-col md:flex-row flex-wrap p-5 sm:p-10">
            {data.textReviewObject.list.map((element) => (
              <article className="flex w-full md:w-1/2">
                <div className="flex justify-center items-center rounded-full max-h-8 sm:max-h-12 max-w-[3rem] bg-brand-black ">
                  <IconSelect
                    iconName={element.iconName}
                    className={`w-8 sm:w-12 max-h-12 scale-50 fill-current text-white`}
                  />
                </div>
                <RenderMarkdown
                  markdownContent={element.review}
                  className="px-5"
                />
              </article>
            ))}
          </div>
        </Section>

        {/*------------------------- benefits -------------------------*/}
        <Section className="">
          <BenefitsList
            benefitsObject={data.benefitsObject}
            classNameIcon="w-14 p-2 sm:p-4 text-brand-green"
          />
        </Section>

        {/*------------------------- special -------------------------*/}
        <Section className="bg-brand-beige-40">
          <div className="flex flex-row flex-nowrap gap-2 pb-5">
            <PreviewCompatibleImage
              imageObject={data.specialObject.imageObject1}
              className="w-1/3"
            />
            <PreviewCompatibleImage
              imageObject={data.specialObject.imageObject2}
              className="w-1/3"
            />
            <PreviewCompatibleImage
              imageObject={data.specialObject.imageObject3}
              className="w-1/3"
            />
          </div>
          <RenderMarkdown
            markdownContent={data.specialObject.description}
            className="text-center"
          />
          <BenefitsList
            benefitsObject={data.specialObject.benefitsObject}
            classNameIcon="w-11 p-3 sm:p-7"
            isIconCentered={true}
            whitespaceList="normal"
          />
        </Section>

        {/*------------------------- join -------------------------*/}
        <Section
          className="bg-brand-green text-white text-center"
          id={`${data.joinObject.anchor || ""}`}
        >
          <RenderMarkdown markdownContent={data.joinObject.description} />
          <div className="flex justify-center">
            <BenefitsList
              benefitsObject={data.joinObject.benefitsObject}
              classNameIcon="w-11 p-3 sm:p-7"
              classNameListText="text-left"
            />
          </div>
          <CountdownTimer
            date={data.configObject.date}
            className="my-10"
            classNameDigits="font-semibold"
          />
          <RenderMarkdown markdownContent={data.joinObject.ctaTitle} />
          <Cta
            ctaObject={data.joinObject.ctaObject}
            className="py-5 sm:py-10"
          />
        </Section>

        {/*------------------------- introduction -------------------------*/}
        <Section>
          <RenderMarkdown
            markdownContent={data.introductionTitle}
            className="text-center"
          />
          <div className="space-y-5 sm:space-y-10">
            <Introduction
              introductionObject={data.introductionObject}
              isReversed={true}
            />
            <Introduction introductionObject={data.secondIntroductionObject} />
          </div>
        </Section>

        {/*------------------------- criteriaText -------------------------*/}
        <Section className="bg-brand-green text-white">
          <RenderMarkdown markdownContent={data.criteriaText} />
          <Cta ctaObject={data.criteriaCtaObject} className="py-5 sm:py-10" />
        </Section>

        {/*------------------------- faqs -------------------------*/}
        <Section className="bg-brand-beige-40">
          <FAQs faqsObject={data.faqsObject} />
        </Section>

        {/*------------------------- nextSteps -------------------------*/}
        <Section>
          <RenderMarkdown
            markdownContent={data.nextStepsObject.title}
            className="text-center"
          />
          <FlexGroup
            ElementName={FeaturesElement}
            list={data.nextStepsObject.nextStepsList}
          />
          <CountdownTimer
            date={data.configObject.date}
            className="my-10"
            classNameDigits="font-semibold"
          />
          <RenderMarkdown markdownContent={data.nextStepsObject.ctaTitle} />
          <Cta
            ctaObject={data.nextStepsObject.ctaObject}
            className="py-5 sm:py-10"
          />
        </Section>

        {/*------------------------- finalText -------------------------*/}
        <Section className="bg-brand-green text-white">
        {/* TODO: Refactor to use finalText Component */}
          <RenderMarkdown markdownContent={data.finalObject.finalText} />
          <div className="max-w-2xl mx-auto mb-5">
            {/* style to fix safari overflow bug */}
            <div
              className="w-1/3 rounded-full overflow-hidden"
              style={{ transform: "translateZ(0)" }}
            >
              <PreviewCompatibleImage
                imageObject={data.finalObject.imageObject}
                className="scale-125"
              />
            </div>
          </div>
          <RenderMarkdown markdownContent={data.finalObject.finalMessage} />
          {/* <Cta ctaObject={data.ctaObject3} /> */}
        </Section>
      </Layout>
    </>
  );
};

function FeaturesElement({ imageObject, description }) {
  return (
    <article className="flex flex-col items-center text-center p-0 sm:p-5 w-full sm:w-1/2 md:w-1/3 xl:w-1/3">
      <div className="h-40 md:h-48 lg:h-60 flex flex-col justify-center mb-3">
        <PreviewCompatibleImage
          imageObject={imageObject}
          className="rounded-3xl max-h-full max-w-xs sm:max-w-none"
        />
      </div>
      <RenderMarkdown markdownContent={description} isMarkdown={true} />
    </article>
  );
}

const YetAnotherTemplate2Page = ({ data }) => {
  return (
    <YetAnotherTemplate2PageTemplate data={data.markdownRemark.frontmatter} />
  );
};

export default YetAnotherTemplate2Page;

export const pageQuery = graphql`
  query YetAnotherTemplate2Page($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seoObject {
          slug
          domain
          description
          index
          title
          imageObject {
            alt
            image {
              childImageSharp {
                resize(width: 1200) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
        configObject {
          date
        }
        headerObject {
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
          }
        }
        heroObject {
          title
          subtitle
          video {
            cloudflareId
            hasGifThumbnail
          }
          ctaObject {
            linkText
            linkUrl
          }
        }
        famousObject {
          title
          imageObject {
            image {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            alt
            mobileImage {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
        youKnowObject {
          title
          list {
            description
            iconName
          }
        }
        youKnowText
        imagineObject {
          title
          description
        }
        pitchBenefitsObject {
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
          }
          benefitsObject {
            title
            list {
              description
              iconName
            }
          }
          ctaObject {
            linkText
            linkUrl
          }
        }
        textReviewObject {
          title
          list {
            iconName
            review
          }
        }
        benefitsObject {
          title
          list {
            description
            iconName
          }
        }
        specialObject {
          imageObject1 {
            alt
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          imageObject2 {
            alt
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          imageObject3 {
            alt
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          description
          benefitsObject {
            list {
              description
              iconName
            }
            postscript
          }
        }
        joinObject {
          anchor
          description
          benefitsObject {
            list {
              description
              iconName
            }
          }
          ctaTitle
          ctaObject {
            linkText
            linkUrl
          }
        }
        introductionTitle
        introductionObject {
          introductionText
          imageSubtext
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        secondIntroductionObject {
          introductionText
          imageSubtext
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        criteriaText
        criteriaCtaObject {
          linkText
          linkUrl
        }
        faqsObject {
          title
          qAndA {
            question
            answer
          }
        }
        nextStepsObject {
          title
          nextStepsList {
            description
            imageObject {
              alt
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          ctaTitle
          ctaObject {
            linkText
            linkUrl
          }
        }
        finalObject {
          finalText
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
          }
          finalMessage
        }
      }
    }
  }
`;
